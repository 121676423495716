import React from "react";
import "./qualification.css";

const Qualification = () => {
  return (
    <section className='qualification section' id='qualification'>
      <h2 className='section__title'>Qualification</h2>
      <span className='section__subtitle'>My educational journey</span>

      <div className='qualification__container container'>
        <div className='qualification__headers'>
          <div className='qualification__header'>
            <i className='uil uil-graduation-cap qualification__icon'></i>
            <h3 className='qualification__header-title'>Education</h3>
          </div>
          <div className='qualification__header'>
            <i className='uil uil-briefcase-alt qualification__icon'></i>
            <h3 className='qualification__header-title'>Experience</h3>
          </div>
        </div>
        <div className='qualification__timeline'>
          {/* Education Section */}
          <div className='qualification__item left'>
            <div className='qualification__content'>
              <h3 className='qualification__title'>Schooling</h3>
              <span className='qualification__subtitle'>South Point School</span>
              <div className='qualification__calendar'>
                <i className='uil uil-calendar-alt'></i> 2007 - 2021
              </div>
            </div>
          </div>

          <div className='qualification__item left'>
            <div className='qualification__content'>
              <h3 className='qualification__title'>Computer Science Engineering</h3>
              <span className='qualification__subtitle'>
                Institute of Engineering & Management, Kolkata
              </span>
              <div className='qualification__calendar'>
                <i className='uil uil-calendar-alt'></i> 2021 - 2025
              </div>
            </div>
          </div>

          {/* Experience Section */}
          <div className='qualification__item right'>
            <div className='qualification__content'>
              <h3 className='qualification__title'>Information Technology Intern</h3>
              <span className='qualification__subtitle'>Medica Superspeciality Hospital</span>
              <div className='qualification__calendar'>
                <i className='uil uil-calendar-alt'></i> 5th June 2023 - 5th August 2023
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
