import React, { useState, useCallback } from "react";
import "./services.css";

const ServiceModal = ({ isOpen, onClose, title, description, services }) => {
  if (!isOpen) return null;

  return (
    <div className="services__modal active-modal">
      <div className="services__modal-content">
        <button onClick={onClose} className="services__modal-close">
          <i className="uil uil-times"></i>
        </button>
        <h3 className="services__modal-title">{title}</h3>
        <p className="services__modal-description">{description}</p>
        <ul className="services__modal-services">
          {services.map((service, index) => (
            <li key={index} className="services__modal-service">
              <i className="uil uil-check-circle services__modal-icon"></i>
              <p className="services__modal-info">{service}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Services = () => {
  const [activeModal, setActiveModal] = useState(null);

  const toggleModal = useCallback((index) => {
    setActiveModal(activeModal === index ? null : index);
  }, [activeModal]);

  const services = [
    {
      icon: "uil-web-grid",
      title: "Web Development",
      modalTitle: "Custom Web Solutions",
      description: "Crafting responsive and user-friendly websites tailored to your needs.",
      services: [
        "Modern UI/UX design for engaging user experiences",
        "Full-stack development using latest technologies",
        "Responsive design for seamless mobile and desktop use",
        "E-commerce solutions and CMS integration",
        "Performance optimization and SEO implementation"
      ]
    },
    {
      icon: "uil-arrow",
      title: "Data Analytics",
      modalTitle: "Understand your data",
      description: "Service with more than 2 years of experience. Providing quality work to clients and companies.",
      services: [
        "Leveraged expertise in IBM SPSS to analyze and interpret patient feedback data, providing valuable insights to clients.",
        "Conducted Reliability Analysis to ensure the consistency and accuracy of collected data, enhancing the reliability of results.",
        "Applied Exploratory Factor Analysis (EFA) to identify underlying patterns and structures within the patient feedback dataset.",
        "Executed Confirmatory Factor Analysis (CFA) to validate and confirm the identified factors, ensuring robust and reliable analytical outcomes."
      ]
    }
  ];

  return (
    <section className="services section" id="services">
      <h2 className="section__title">Freelance</h2>
      <span className="section__subtitle">Services I offer</span>
      <div className="services__container container">
        {services.map((service, index) => (
          <div key={index} className="services__content">
            <i className={`uil ${service.icon} services__icon`}></i>
            <h3 className="services__title">{service.title}</h3>
            <span className="services__button" onClick={() => toggleModal(index)}>
              View More
              <i className="uil uil-arrow-right services__button-icon"></i>
            </span>
            <ServiceModal
              isOpen={activeModal === index}
              onClose={() => toggleModal(index)}
              title={service.modalTitle}
              description={service.description}
              services={service.services}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;